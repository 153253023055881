import { TabProps } from '@web/molecules/WidgetTabs/Tabs';
import styles from '@web/molecules/WidgetTabs/Tabs.module.scss';

export const Tab = ({
    label,
    dataKey,
    highlighted,
    onTabClick,
}: {
    label: string;
    dataKey: string;
    highlighted?: string;
    onTabClick: TabProps['onTabClick'];
}) => (
    <li
        id={`${label}-tab`}
        onClick={onTabClick(dataKey)}
        className={`${styles['tab']} ${highlighted === dataKey ? styles['tab-highlighted'] : ''}`}
    >
        {label}
    </li>
);
