import { isNumber } from 'lodash';

import { News } from '@common/clients/api';
import { DateTimeUtil } from '@common/utils/DateTimeUtil';
import { formatNumber, SHORTEN } from '@common/utils/NumbersUtil';

type MetaKey = 'newsDate' | 'commentCount' | 'visitCount';
type ExtendedNews = News & { [key in MetaKey]?: string | number };
const formatItemMeta = (metaValue: string | number | undefined, metaKey: MetaKey) => {
    if (!metaValue) {
        return {};
    }
    if (metaKey === 'newsDate') {
        const date = new Date(metaValue);
        return { date: DateTimeUtil.format(date, DateTimeUtil.formats.DATE_MEDIUM) };
    }
    return { count: isNumber(metaValue) ? formatNumber(Number(metaValue), SHORTEN.SMALL) : '0' };
};

/**
 * Map fetched data to the format that is used by the component
 *
 * @param {string} newsItemKey - key of the count property in the fetched data
 * @param {boolean} addIndex - add index to the listed items to be shown in a side widget
 *
 * @example
 * mapFetchedData('commentCount')(commentItems)
 * // Returns [{ src: 'https://..*.jpeg', title: 'An article title', count: 256, url:'..*.html' }]
 */
export const mapFetchedData = (newsItemKey: MetaKey, addIndex?: boolean) => {
    return (dataItems: Partial<ExtendedNews>[]) => {
        const mappedItems = dataItems.map((currentItem, i) => {
            const itemMeta = formatItemMeta(currentItem[newsItemKey], newsItemKey);
            const index = addIndex ? i + 1 : undefined;

            return {
                src: currentItem.image || '',
                title: currentItem.newsTitle || '',
                url: currentItem.path,
                ...itemMeta,
                index,
            };
        });

        return mappedItems;
    };
};
