import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { Comment as FetchedComment } from '@common/clients/api';
import { fetchMostLikedComments } from '@web/handlers/fetchMostLikedComments';
import { SimpleComment, SimpleCommentProps } from '@web/molecules/SimpleComment';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: SimpleCommentProps[];
    staticData?: SimpleCommentProps[];
}

const mapComment = (comment: FetchedComment) => ({
    ...comment,
    id: comment.id || 0,
    user: comment.user || {},
    likeCount: comment.likeCount || 0,
    reactionTxt: comment.reactionTxt || '',
});

export const mapFetchedComments = (comments: FetchedComment[]) => comments.map(mapComment);

export const Widget: FC<Props> = ({ dataList, staticData }) => {
    const __sidebar = useTranslation('sidebar').t;
    return (
        <SimpleWidget
            title={switchNewsBlockTitle('liked', __sidebar)}
            metaKey={'liked'}
            ComponentItem={SimpleComment}
            dataList={dataList}
            staticData={staticData as SimpleCommentProps[]}
        />
    );
};

export const fetcherProps: FetcherProps<FetchedComment[], SimpleCommentProps[]> = {
    fetcher: fetchMostLikedComments,
    mapper: mapFetchedComments,
    storageKey: WidgetType.MOST_LIKED_COMMENTS,
};
