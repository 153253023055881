import { FC } from 'react';

import { ComponentList } from '@web/molecules/ComponentList/ComponentList';

import { Tab } from './atoms';

import styles from './Tabs.module.scss';

export interface TabProps {
    tabLabels: Array<string>;
    tabDataKeys: Array<string>;
    onTabClick: (clickedTab: string) => () => string;
    highlighted?: string;
}

export const Tabs: FC<TabProps> = ({ tabLabels, tabDataKeys, onTabClick, highlighted }) => (
    <ComponentList
        classes={styles.Tabs}
        ComponentItem={Tab}
        propsList={[
            ...tabLabels.map((label: string, i: number) => ({
                label,
                dataKey: tabDataKeys[i],
                highlighted,
                onTabClick,
            })),
        ]}
    />
);
