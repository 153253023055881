import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { News } from '@common/clients/api';
import { fetchCategoryNews, ItemsPerWidget, NewsCatFunctionality } from '@web/handlers/fetchCategoryNews';
import { NewsBlockItem, Props as NewsBlockItemProps } from '@web/molecules/NewsBlockItem/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { mapFetchedData, switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
    staticData?: NewsBlockItemProps[];
}

export const Widget: FC<Props> = ({ dataList, staticData }) => {
    const __sidebar = useTranslation('sidebar').t;

    return (
        <SimpleWidget
            title={switchNewsBlockTitle('podcast', __sidebar)}
            metaKey={'podcast'}
            ComponentItem={NewsBlockItem}
            dataList={dataList}
            staticData={staticData}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchCategoryNews({
        functionality: NewsCatFunctionality.PODCAST,
        perWidget: ItemsPerWidget.EXTRA_SMALL,
    }),
    mapper: mapFetchedData('newsDate', true),
    storageKey: WidgetType.LATEST_PODCASTS,
};
