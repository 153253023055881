import { News } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { fetchNews } from '@web/handlers/fetchNews';

import { fetchNewsCategoryByFunctionality } from '../fetchNewsCategoryByFunctionality';

export enum ItemsPerWidget {
    MICRO = 3,
    EXTRA_SMALL = 5,
}

export enum NewsCatFunctionality {
    TRANSFER_RUMORS = 'transfergeruchten',
    PODCAST = 'podcast',
}

type FetchParams = {
    perWidget: ItemsPerWidget;
    functionality: NewsCatFunctionality;
};

export const fetchCategoryNews =
    (fetchParams: FetchParams) =>
    async (contextData: ContextData, isClientSide: boolean = false): Promise<News[]> => {
        const newsCategory = await fetchNewsCategoryByFunctionality(
            contextData,
            isClientSide,
            fetchParams.functionality,
        );
        const newsCatID = newsCategory?.id;

        if (newsCatID) {
            const response = await fetchNews({
                contextData,
                isClientSide,
                newsCatID,
                perPage: fetchParams.perWidget,
            });

            return response.data;
        }
        return [];
    };
