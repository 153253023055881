import { FC, memo, ReactNode, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Header as WidgetHeader } from '@web/atoms/Header';
import { ComponentList as WidgetItemList } from '@web/molecules/ComponentList';
import { WidgetTitle } from '@web/molecules/Widget';
import Widget from '@web/molecules/Widget/Widget';
import styles from '@web/molecules/Widget/Widget.module.scss';

import { HighlightedCategory } from '../SideWidgets/types';
import { switchHighlightedMeta, switchMetaIcon } from '../SideWidgets/utils';

interface Props<T> {
    minimalUI?: boolean;
    ComponentItem: FC<T>;
    dataList?: T[];
    metaKey: HighlightedCategory;
    renderHeader?: () => ReactNode;
    staticData?: T[];
    title?: string;
    titleClasses?: string;
}

/**
 * SimpleWidget is a widget organism/template that is used to render a sidebar component
 * with a title, header, and a list of ComponentItem(s).
 * @param {Object} props - The props object.
 * @param {boolean} props.minimalUI - If true, the widget will render without a title and header.
 * @param {FC<T>} props.ComponentItem - A listable component like NewsBlockItem.
 * @param {T[]} props.dataList - If provided, the widget will render with fetched data (DataFetcher).
 * @param {HighlightedCategory} props.metaKey - A key that is used to determine the meta icon and meta suffix.
 * @param {() => ReactNode} props.renderHeader - Add JSX to the header, e.g., extra buttons.
 * @param {T[]} props.staticData - If provided, the widget will render with static data (Storybook).
 * @param {string} props.title - A title for the widget.
 * @param {string} props.titleClasses - A class for the title.
 * @returns {JSX.Element}
 */
const WidgetBase = <T,>({
    minimalUI,
    ComponentItem,
    dataList,
    metaKey,
    renderHeader,
    staticData,
    title,
    titleClasses,
}: Props<T>): JSX.Element => {
    const __sidebar = useTranslation('sidebar').t;
    const highlightedTabData = useMemo(
        () => ({
            metaSuffix: switchHighlightedMeta(metaKey, __sidebar),
            metaIcon: switchMetaIcon(metaKey),
        }),
        [metaKey, __sidebar],
    );

    return minimalUI ? (
        <WidgetItemList
            classes={styles['Widget-list']}
            ComponentItem={(props: T) => <ComponentItem {...props} {...highlightedTabData} />}
            propsList={(staticData as T[]) || dataList}
        />
    ) : (
        <Widget>
            <WidgetHeader classes={styles['Widget-header']} flexDirection="row">
                <WidgetTitle
                    heading={title}
                    classes={titleClasses ? styles[titleClasses] : styles['border-third']}
                />
                {renderHeader && renderHeader()}
            </WidgetHeader>
            <WidgetItemList
                classes={styles['Widget-list']}
                ComponentItem={(props: T) => <ComponentItem {...props} {...highlightedTabData} />}
                propsList={(staticData as T[]) || dataList}
            />
        </Widget>
    );
};

/**
 *  This type assertion is needed to avoid use of the generic SimpleWidget to throw
 *  errors passing in a generic ComponentItem prop.
 *  @returns {JSX.Element} - Memoized SimpleWidget.
 */
export const SimpleWidget = memo(WidgetBase) as typeof WidgetBase;
