import { FC } from 'react';

import { Author } from '@common/clients/api';
import { SocialLink, SocialLinkNetwork } from '@web/atoms/SocialLink';

import styles from './AuthorInfo.module.scss';

interface Props extends Author {
    className?: string;
}

export const AuthorInfo: FC<Props> = ({ linkedin, twitter, className }): JSX.Element => (
    <>
        <div className={`${styles.AuthorInfo} ${className}`}>
            {twitter ? <SocialLink socialNetwork={SocialLinkNetwork.TWITTER} url={twitter} /> : null}
            {linkedin ? <SocialLink socialNetwork={SocialLinkNetwork.LINKEDIN} url={linkedin} /> : null}
        </div>
        <hr />
    </>
);
