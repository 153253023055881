import { FC, ReactNode } from 'react';
import { isNumber } from 'lodash';

import { WebpWidthEnum } from '@common/types/WebpWidth';
import { Counter as NewsBlockItemCounter } from '@web/atoms';
import { Heading as NewsBlockItemHeading } from '@web/atoms/Heading';
import { WebpImage } from '@web/atoms/WebpImage';

import { Container } from './atoms/Container';

import styles from './NewsBlockItem.module.scss';

const getMetaString = (...args: Array<string | number | undefined>) =>
    args.filter((arg) => typeof arg !== 'undefined').join(' ');

export interface Props {
    src?: string;
    index?: number;
    title?: string;
    count?: string;
    date?: string;
    metaSuffix?: string;
    metaIcon?: ReactNode;
    url?: string;
    icon?: string;
}

export const NewsBlockItem: FC<Props> = ({ src, index, title, count, date, metaSuffix, metaIcon, url }) => {
    if (!title) {
        return null;
    }

    const metaString = getMetaString(count, date, metaSuffix);

    return (
        <Container classes={styles.NewsBlockItem}>
            <a href={url}>
                {isNumber(index) ? <NewsBlockItemCounter classes={styles['counter']} tag={index} /> : null}
                <WebpImage
                    defaultSize={WebpWidthEnum.WIDTH_110}
                    className={styles.image}
                    src={`${src}`}
                    alt={title}
                    ratio={110 / 60}
                />
                <div className={styles['rapper']}>
                    <NewsBlockItemHeading as={'h4'} className={styles['heading']} heading={title} />
                    <div className={styles['meta']}>
                        {metaIcon ? metaIcon : null}
                        {metaString.length ? <span>{metaString}</span> : null}
                    </div>
                </div>
            </a>
        </Container>
    ) as JSX.Element;
};
