import { FC } from 'react';

import styles from './Counter.module.scss';

interface Props {
    tag?: string | number;
    classes?: string;
}

export const Counter: FC<Props> = ({ tag, classes }) =>
    tag ? <span className={`${classes} ${styles.Counter} ${styles.highlighted}`}>{tag}</span> : null;
